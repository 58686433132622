module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"articles":"/opt/build/repo/src/components/article-container/article-container.tsx"},"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"81980e04-5ea1-56ac-a244-a189611cde2f","name":"gatsby-remark-images","version":"6.13.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"backgroundColor":"transparent","maxWidth":800,"quality":90},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"backgroundColor":"transparent","maxWidth":800,"quality":90},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
